import { PrismicLink, validateHtml, validateText } from "utils/renderHelpers";
import React, { PureComponent } from "react";
import posed, { PoseGroup } from "react-pose";

import BreakpointListener from "components/BreakpointListener";
import { CgArrowLongRight } from "react-icons/cg";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Hero from "react-bulma-components/lib/components/hero";
import PropTypes from "prop-types";
import SplitText from "react-pose-text";
import Swipeable from "react-swipeable";
import YTPlayer from "components/YTPlayer";
import classNames from "classnames/bind";
import events from "utils/events";
import { renderFluidImage } from "utils/imageHelpers";
import { sine } from "utils/easing";
import styles from "./HeroCarousel.module.scss";

const cx = classNames.bind(styles);

const AnimatedArticle = posed.article({
  preEnter: {
    opacity: 0,
    x: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      opacity: {
        delay: 600,
        type: "tween",
        duration: 1,
      },
    },
  },
  exit: {
    x: 600,
    transition: {
      x: {
        delay: 300,
        type: "tween",
        duration: 600,
        ease: sine.in,
      },
    },
  },
});

const BarContainer = posed.div({
  enter: { beforeChildren: true, staggerChildren: 100 },
  exit: { staggerChildren: 100, staggerDirection: -1 },
});

const Bar = posed.figure({
  preEnter: {
    opacity: ({ idx, targetOpacity }) => (idx === 0 ? targetOpacity : 0),
    x: ({ idx }) => (idx === 0 ? 0 : `-100%`),
  },
  enter: {
    opacity: ({ targetOpacity }) => targetOpacity,
    x: 0,
    transition: {
      x: { delay: 700, type: "tween", ease: sine.out, duration: 600 },
      opacity: { delay: 700, type: "tween", ease: sine.out, duration: 300 },
    },
  },
  exit: {
    x: 1000,
    transition: {
      x: { type: "tween", ease: sine.in, duration: 400 },
    },
  },
});

const AnimatedOverlay = posed.figure({
  out: {
    x: "100%",
    transition: {
      x: {
        from: "0%",
        to: `100%`,
        type: "tween",
        ease: sine.in,
        duration: 400,
      },
    },
  },
  in: {
    x: `0%`,
    transition: {
      x: {
        from: "-100%",
        to: `0%`,
        type: "tween",
        ease: sine.out,
        duration: 600,
      },
    },
  },
});
// headings
const headingAnimationProps = {
  charPoses: {
    exit: {
      opacity: 0,
      y: 0,
      transition: {
        duration: 200,
      },
    },
    preEnter: {
      opacity: 0,
      y: 40,
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: ({ enterDelay }) => ({
        type: "tween",
        ease: sine.out,
        duration: 400,
        delay: enterDelay + 1500,
      }),
    },
  },
};

// other children animations
const childAnim = (delay, yOffset = 25) => {
  return {
    preEnter: {
      opacity: 0,
      y: yOffset,
    },
    exit: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        opacity: {
          delay,
          type: "tween",
          duration: 300,
          ease: sine.out,
        },
        y: {
          delay,
          type: "tween",
          duration: 400,
          ease: sine.out,
        },
      },
    },
  };
};

const AnimatedCategory = posed.h4(childAnim(1400, 10));
const AnimatedParagraph = posed.div(childAnim(1750, 15));
const AnimatedButtonContainer = posed.div(childAnim(1900, 15));

export default class HeroCarousel extends PureComponent {
  first = true;

  static propTypes = {
    delay: PropTypes.number.isRequired,
  };

  static defaultProps = {
    delay: 10,
  };

  constructor(props) {
    super(props);
    this.items = props.items.filter(
      (item) =>
        item.button_link && item.category.text.indexOf("__template") === -1
    );
    this.state = {
      rendered: false,
      isMobile: false,
      index: 0,
      inTransition: true,
      canTransition: false,
    };
  }

  getAnimatedHeading(html) {
    html = html.replace("<h1>", "");
    html = html.replace("</h1>", "");
    html = html.replace("[supopen]", "<sup>");
    html = html.replace("[supclose]", "</sup>");
    let sep = html.indexOf("<br />") > 0 ? "<br />" : "<br/>";
    let aHtml = html.split(sep);

    return (
      <React.Fragment>
        {aHtml.map((piece, idx) => {
          return (
            <React.Fragment key={`heading-${idx}`}>
              <SplitText enterDelay={idx * 200} {...headingAnimationProps}>
                {piece}
              </SplitText>
              {idx < aHtml.length - 1 && <br />}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  onBreakpoint = ({ newSize }) => {
    this.setState({ isMobile: newSize === "mobile" });
  };

  onWheel = (e) => {
    if (e.deltaY < 0) {
      this.prev();
    } else if (e.deltaY > 0) {
      this.next();
    }
  };

  componentDidMount() {
    //this.interval = setInterval(this.toggle, this.props.delay * 1000);
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
    window.addEventListener("mousewheel", this.onWheel);
    setTimeout(() => {
      this.setState({
        rendered: true,
        isMobile:
          BreakpointListener.size === "mobile" ||
          BreakpointListener.size === "tablet",
      });
    }, 0);
    this.transitionTimout = setTimeout(() => {
      this.first = false;
      this.setState({
        inTransition: null,
        canTransition: true,
      });
    }, 1000);
  }

  componentWillUnmount() {
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
    window.removeEventListener("mousewheel", this.onWheel);
    clearInterval(this.interval);
  }

  renderItem = (idx) => {
    const { isMobile } = this.state;
    let currentItem = this.items[idx];
    let nextItem = this.items[idx + 1];
    if (!nextItem) {
      nextItem = this.items[0];
    }
    // let slug = null;

    let {
      theme,
      category,
      heading,
      image,
      paragraph,
      button_label,
      button_link,
      youtube_embed,
      loop_start_time,
      loop_end_time,
    } = currentItem;
    let doc = button_link.document;

    if (doc) {
      doc = doc[0];
      // slug = doc.slug;
      const hero = doc.data.body.filter(
        (item) => item.slice_type === "hero"
      )[0];
      const { primary } = hero;

      if (!theme || (theme && theme.indexOf("none") !== -1)) {
        theme = primary.theme;
      }

      if (!button_label) {
        button_label = doc.data.title;
      }

      if (!image.url && !image.localFile) {
        image = primary.image;
      }

      if (!heading || !heading.text || !heading.text.length) {
        heading = primary.heading;
      }

      if (!paragraph || !paragraph.html || !paragraph.html.length) {
        paragraph = primary.paragraph;
      }

      if (!youtube_embed || !youtube_embed.embed_url) {
        youtube_embed = primary.youtube_embed;
      }

      if (youtube_embed && youtube_embed.embed_url) {
        if (
          loop_start_time === null ||
          loop_start_time === undefined ||
          loop_start_time === ""
        ) {
          loop_start_time = primary.loop_start_time;
        }
        if (
          loop_end_time === null ||
          loop_end_time === undefined ||
          loop_end_time === ""
        ) {
          loop_end_time = primary.loop_end_time;
        }
      }
    }

    const hasVideo =
      youtube_embed &&
      youtube_embed.embed_url &&
      youtube_embed.embed_url.length > 0 &&
      loop_start_time !== null &&
      loop_start_time !== undefined &&
      loop_start_time !== "" &&
      loop_end_time !== null &&
      loop_end_time !== undefined &&
      loop_end_time !== ""
        ? true
        : false;

    return (
      <AnimatedArticle
        key={`hero-${idx}`}
        className={`is-theme-${theme} ${styles.heroCarouselItem}`}
      >
        {this.state.rendered && !this.state.isMobile && hasVideo ? (
          <div
            style={{
              position: `absolute`,
              display: `block`,
              top: 0,
              left: 0,
              width: `100%`,
              height: `100%`,
            }}
          >
            <YTPlayer
              key={`herocarousel-player-${idx}`}
              delayLoad={2000}
              className={styles.player}
              embed={youtube_embed}
              loop={true}
              loopStartTime={loop_start_time}
              loopEndTime={loop_end_time}
              coverImage={image || null}
            />
          </div>
        ) : (
          <figure className={styles.image}>
            {renderFluidImage(image, null, { critical: idx === 0 })}
          </figure>
        )}
        <BarContainer className={styles.overlay}>
          <Bar
            idx={0}
            targetOpacity={0.4}
            className={`bg-theme-primary ${styles.bar}`}
            style={{
              width: isMobile ? `calc(100% + 100px)` : `calc(92% + 100px)`,
            }}
          />
          <Bar
            idx={2}
            targetOpacity={0.35}
            className={`bg-theme-primary ${styles.bar}`}
            style={{
              width: isMobile ? `calc(90% + 100px)` : `calc(72% + 100px)`,
            }}
          />
          <Bar
            idx={3}
            targetOpacity={0.4}
            className={`bg-theme-primary ${styles.bar}`}
            style={{
              width: isMobile ? `calc(82.5% + 100px)` : `calc(60% + 100px)`,
            }}
          />
          <Bar
            idx={4}
            targetOpacity={0.45}
            className={`bg-theme-primary ${styles.bar}`}
            style={{
              width: isMobile ? `calc(75% + 100px)` : `calc(40% + 100px)`,
            }}
          />
        </BarContainer>
        <aside className={styles.heroCarouselItemBodyWrapper}>
          <Hero.Body className={styles.heroCarouselItemBody}>
            <Container style={{ maxWidth: `100%` }}>
              <Columns mobile multiline>
                <Columns.Column
                  className={styles.counter}
                  mobile={{ size: 12 }}
                  tablet={{ size: 1 }}
                >
                  {this.items.map((item, i) => {
                    return (
                      <button
                        key={`counter-${i}`}
                        onClick={() => {
                          this.setIndex(i);
                        }}
                        className={cx({ current: idx === i, navButton: true })}
                      >{`0${i + 1}.`}</button>
                    );
                  })}
                </Columns.Column>
                <Columns.Column
                  mobile={{ size: 10 }}
                  tablet={{ size: 6 }}
                  widescreen={{ size: 6 }}
                >
                  {validateText(category) && (
                    <AnimatedCategory className={styles.category}>
                      {category.text}
                    </AnimatedCategory>
                  )}
                  {validateHtml(heading) && (
                    <h2 className={styles.heading}>
                      {this.getAnimatedHeading(heading.html)}
                    </h2>
                  )}
                  {validateHtml(paragraph) && (
                    <AnimatedParagraph
                      className={styles.paragraph}
                      dangerouslySetInnerHTML={{ __html: paragraph.html }}
                    />
                  )}
                  {button_label && button_link && (
                    <AnimatedButtonContainer>
                      <PrismicLink
                        link={button_link}
                        className={`button is-outlined ${styles.button}`}
                      >
                        <span>{button_label.text}</span>
                        <i>
                          <CgArrowLongRight />
                        </i>
                      </PrismicLink>
                    </AnimatedButtonContainer>
                  )}
                </Columns.Column>
              </Columns>
            </Container>
          </Hero.Body>
        </aside>
      </AnimatedArticle>
    );
  };

  toggle = () => {
    let index =
      this.state.index === this.items.length - 1 ? 0 : this.state.index + 1;
    this.change(index);
  };

  next = () => {
    let index =
      this.state.index === this.items.length - 1 ? 0 : this.state.index + 1;
    this.change(index);
  };

  prev = () => {
    let index =
      this.state.index === 0 ? this.items.length - 1 : this.state.index - 1;
    this.change(index);
  };

  setIndex = (index) => {
    this.change(index);
  };

  change = (index) => {
    if (!this.state.canTransition) {
      return;
    }

    clearTimeout(this.transitionTimeout);
    clearTimeout(this.canTransitionTimeout);

    this.first = false;

    this.setState({ index, inTransition: true, canTransition: false });
  };

  onSwiped = (dir) => {
    if (dir === 1) {
      this.next();
    } else if (dir === -1) {
      this.prev();
    }
  };

  handlePoseComplete = (pose) => {
    if (!this.first) {
      if (pose === `in`) {
        this.setState({ inTransition: false });
      } else if (pose === `out`) {
        this.setState({ ...this.state, canTransition: true });
      }
    }
  };

  render() {
    const { id } = this.props;
    return (
      <Swipeable
        trackMouse
        style={{ touchAction: "none" }}
        preventDefaultTouchmoveEvent
        onSwipedLeft={() => this.onSwiped(-1)}
        onSwipedRight={() => this.onSwiped(1)}
      >
        <Hero className={`hero ${styles.hero}`} key={id}>
          <PoseGroup
            flipMove={false}
            animateOnMount={true}
            preEnterPose={"preEnter"}
            enterAfterExit={true}
          >
            {this.renderItem(this.state.index)}
          </PoseGroup>
          <AnimatedOverlay
            pose={this.state.inTransition ? "in" : "out"}
            onPoseComplete={this.handlePoseComplete}
            className={`${cx({
              animatedOverlay: true,
              [this.items[this.state.index].theme]: true,
            })}`}
          />
        </Hero>
      </Swipeable>
    );
  }
}
