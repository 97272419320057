import React, { Component } from "react";
// Components
import Helmet from "react-helmet";
import HeroCarousel from "slices/HeroCarousel";
import getPageMeta from "utils/getPageMeta";
// graphql
import { graphql } from "gatsby";

class HomePage extends Component {
  renderSlice = (slice) => {
    if (!slice) {
      return null;
    }
    slice.delay = 10;
    return <HeroCarousel {...slice} key={slice.id} />;
  };

  render() {
    const { page } = this.props.data;
    const { title, body } = page.data;
    return (
      <React.Fragment>
        <Helmet
          title={`${title.text} | Citizen Relations`}
          meta={getPageMeta(
            this.props.pageContext.globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {body.map((slice) => {
          return this.renderSlice(slice);
        })}
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query($uid: String!) {
    page: prismicHomePage(uid: { eq: $uid }) {
      uid
      data {
        seo_description {
          text
        }
        seo_keywords {
          text
        }
        seo_image {
          twitter {
            url

            dimensions {
              width
              height
            }
          }
          facebook {
            url

            dimensions {
              width
              height
            }
          }
        }
        seo_image_alt {
          text
        }
        title {
          text
        }
        body {
          ... on PrismicHomePageBodyHeroCarouselItem {
            id
            slice_type
            items {
              theme
              youtube_embed {
                embed_url
                width
                height
              }
              loop_start_time
              loop_end_time
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              category {
                text
              }
              heading {
                text
                html
              }
              paragraph {
                html
              }
              button_label {
                text
              }
              button_link {
                url
                raw {
                  link_type
                  target
                }
                document {
                  ... on PrismicCaseStudy {
                    slug
                    data {
                      body {
                        ... on PrismicCaseStudyBodyHero {
                          id
                          slice_type
                          primary {
                            heading {
                              text
                              html
                            }
                            image {
                              url
                              dimensions {
                                width
                                height
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicCrisisCommunications {
                    slug
                    data {
                      body {
                        ... on PrismicCrisisCommunicationsBodyHero {
                          id
                          slice_type
                          primary {
                            heading {
                              text
                              html
                            }
                            image {
                              url
                              dimensions {
                                width
                                height
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicAboutPage {
                    slug
                    data {
                      body {
                        ... on PrismicAboutPageBodyHero {
                          id
                          slice_type
                          primary {
                            heading {
                              text
                              html
                            }
                            image {
                              url
                              dimensions {
                                width
                                height
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
